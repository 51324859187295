@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e0e0e0;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
  background: #9e9e9e;
}

.MuiDataGrid-cellContent {
  font-weight: "bold !important";
}

/* Reset some default styles for better consistency */
.tanstack_table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  /* overflow-x: scroll; */
}

/* Style for table header */
.tanstack_table th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tanstack_table th {
  background-color: #673ab7 !important;
  color: #fff !important;
  font-size: 0.8rem;
  font-weight: 600;
}
